const analytics = {
	identify: function(id = null, payload = {}) {
		if (window.analytics) {
			window.analytics.identify(id, payload);
		} else {
			window.console.info(`[irelo-analytics] identify() - ${id} `, payload);
		}
	},

	page: function(name, payload = {}) {
		if (window.analytics) {
			window.analytics.page(name, payload);
		} else {
			window.console.info(`[irelo-analytics] page() - ${name} `, payload);
		}
	},

	track: function(name, payload = {}) {
		if (window.analytics) {
			window.analytics.track(name, payload);
		} else {
			window.console.info(`[irelo-analytics] track() - ${name} `, payload);
		}
	},
};

export default analytics;
