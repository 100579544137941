require('devbridge-autocomplete');

const isTestSite = window.location.host.indexOf('localhost') !== -1;
const valetApi = '//' + (isTestSite ? 'valet.test' : 'valet.irelo.com') + '/api/v2/service/location';

export default function($input, options) {
	const acOptions = {
		maxHeight: 200,
		minChars: 2,
		noSuggestionNotice: 'No locations found',
		onSearchError: function(query, jqXHR, textStatus) {
			window.console.error(query, jqXHR, textStatus);
			$input[0].setCustomValidity('ERROR: ' + textStatus);
		},
		preserveInput: true,
		showNoSuggestionNotice: true,
		transformResult: function(resp) {
			return {
				suggestions: resp || [],
			};
		},
		// Ajax settings
		dataType: 'json',
		deferRequestBy: 300,
		serviceUrl: valetApi,
	};

	$input.autocomplete($.extend({}, acOptions, options));
}
