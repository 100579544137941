'use strict';

import iAnalytics from './irelo-analytics';
import iAutocomplete from './irelo-autocomplete';

window.$ = window.jQuery = require('jquery');

require('bootstrap/js/dist/util');
require('bootstrap/js/dist/button');
require('bootstrap/js/dist/carousel');
require('bootstrap/js/dist/modal');
require('bootstrap/js/dist/scrollspy');

import moment from 'moment';

require('./polyfills');

const isTestSite = window.location.host.indexOf('localhost') !== -1;
let quote = {};

// Set min date and initial date of 2 weeks
const setInputDate = function() {
	const $inputMoveDate = $('#moving-date');
	const today = moment();

	$inputMoveDate.attr('min', today.format('YYYY-MM-DD'));
	$inputMoveDate.attr('value', today.add(14, 'days').format('YYYY-MM-DD'));
};

$(document).ready(function() {
	Sentry.init({
		dsn: 'https://fe54ccaa01634f469321ffb6cd86812e@sentry.io/1385056',
		release: require('../../package.json').version,
	});

	$('.carousel').carousel({
		interval: 15000,
	});

	let hasSubmittedForm = false;

	iAnalytics.identify();
	iAnalytics.page('landing');

	// Trigger conversion when user clicks on phone number
	$('.js-phone-conversion').on('click', function() {
		iAnalytics.track('Conversion_Calls');
	});

	const $origin = $('#moving-origin');
	const $destination = $('#moving-destination');

	/**
	 *
	 * @param {Event} evt Form submit event
	 *
	 * @returns {void}
	 */
	const onSubmitMoveLocations = function(evt) {
		const form = evt.target;

		evt.preventDefault();

		form.classList.remove('was-validated');

		if (!quote.origin) {
			$origin[0].setCustomValidity(false);
			$origin.focus();
		} else if (!quote.destination) {
			$destination[0].setCustomValidity(false);
			$destination.focus();
		}

		if (form.checkValidity() === false) {
			evt.stopPropagation();
			form.classList.add('was-validated');

			return false;
		}

		// Flatten prop and values into individual properties
		for (const prop in quote.origin) {
			quote[`origin_${prop}`] = quote.origin[prop];
		}

		delete quote.origin;

		// Flatten prop and values into individual properties
		for (const prop in quote.destination) {
			quote[`destination_${prop}`] = quote.destination[prop];
		}

		delete quote.destination;

		if (window.console) {
			window.console.debug(quote);
		}

		if (window.Irelo.source) {
			quote.source = window.Irelo.source;
		}

		iAnalytics.page('move_info');

		$('#modal-quote').modal('show');
	};

	const $formLocation = $('#form-location');

	iAutocomplete($origin, {
		onSelect: function(item) {
			if (window.console) {
				window.console.info(item);
			}

			this.value = item.value;
			quote.origin = item;
			$origin[0].setCustomValidity('');

			if (!quote.destination) {
				$destination.focus();
			}
		},
	});
	iAutocomplete($destination, {
		onSelect: function(item) {
			if (window.console) {
				window.console.info(item);
			}

			this.value = item.value;
			quote.destination = item;
			$destination[0].setCustomValidity('');
			$formLocation.submit();
		},
	});

	// Setup submit events for quote location
	$formLocation.on('submit', onSubmitMoveLocations);

	/* ======= QUOTE INFO SUBMISSION ======== */
	const toggleSubmitButton = function(options) {
		const $btn = $('.js-btn-submit');
		const $loader = $btn.find('.js-loader');
		const $btnText = $btn.find('.js-btn-text');

		if (options.disabled) {
			$btn.attr('disabled', true);
			$loader.addClass('spinner-border');
			$btnText.hide();
		} else {
			$btn.removeAttr('disabled');
			$loader.removeClass('spinner-border');
			$btnText.show();
		}
	};

	/**
	 * Called when submitting the lead after form has been validated
	 *
	 * @returns {void}
	 */
	const submitLead = function() {
		const url = '//coad.irelo.com' + (isTestSite ? '.test' : '');

		$.ajax({
			url: url + '/api/moving/submit',
			data: quote,
			method: 'POST',
		}).done(function(response) {
			let data = response;

			if (typeof response === 'string') {
				data = JSON.parse(response);
			}

			$('#modal-quote').modal('hide');

			Object.assign(quote, data);

			// iAnalytics.track('Conversion', quote);

			window.Irelo.widget(
				quote,
				'Cross Country Moving Group',
				`//${window.location.host}/images/cross-country-moving-group/logo.png`,
				null,
				null,
				'moving'
			);
		})
			.fail(function(response) {

			})
			.always(function() {
				toggleSubmitButton({ disabled: false });
			});
	};

	/**
	 *
	 * @param {Event} evt Form submit event
	 *
	 * @returns {void}
	 */
	const onSubmitMoveInfo = function(evt) {
		const form = evt.target;

		const onFormValidation = function(response) {
			const validateInputs = {
				first_name: $('#moving-first-name'),
				last_name: $('#moving-last-name'),
				email: $('#moving-email'),
				phone: $('#moving-phone'),
			};

			hasSubmittedForm = true;

			// eslint-disable-next-line
			for (const field in validateInputs) {
				validateInputs[field][0].setCustomValidity('');
			}

			if (window.console) {
				window.console.info('onFormValidation | ', response);
			}

			if (response && response.valid === true) {
				submitLead();
			} else {
				const errors = response.errors;

				// eslint-disable-next-line
				for (const field in errors) {
					let $inputField = validateInputs[field] || null;
					const msg = errors[field];

					if (window.console) {
						window.console.debug(`${field} -> ${msg}`);
					}

					if ($inputField) {
						$inputField[0].setCustomValidity(msg);
						$inputField.next('.invalid-feedback').text(msg);
					}
				}

				toggleSubmitButton({ disabled: false });
			}
		};

		evt.preventDefault();
		evt.stopPropagation();

		Object.assign(quote, window.Irelo.getFormData(evt));
		// replace any non-digit characters
		quote.phone = quote.phone.replace(/[\D]/g, '');

		form.classList.add('was-validated');

		if (form.checkValidity() === false && !hasSubmittedForm) {
			return false;
		}

		toggleSubmitButton({ disabled: true });

		window.Irelo.validate({
			formData: quote,
			type: 'moving',
			onValidateComplete: onFormValidation,
		});
	};

	setInputDate();

	$('#form-quotes').on('submit', onSubmitMoveInfo);
});
